<template>

  <div :hidden="!showNotice">
    <LazyUserNotice/>
  </div>
  <NuxtLayout name="header"></NuxtLayout>
  <div class="body__box">
    <LazyUserMenu/>
    <div class="router__view">
      <LazyUserInfo v-if="showUserInfo"/>
      <NuxtPage/>
    </div>
  </div>
  <NuxtLayout name="footer"></NuxtLayout>
  <NuxtLayout name="sidebar" v-if="$device.isDesktop" style="left: calc(50% + 600px + 28px)"></NuxtLayout>
</template>
<script setup>
const route = useRoute();
const router = useRouter();
const store = useUserStore();
const userCookie = getCookie('QZ_KSUser');
if (!store.userInfo.isLogin && !userCookie) {
  router.replace('/login');
}
const showNotice = ref(false);
const hiddenUserInfoRoutes = ['/user/account', '/user/course'];
const showUserInfo = ref(true);
watch(
  () => route.path,
  (newPath) => {
    showUserInfo.value = !hiddenUserInfoRoutes.includes(newPath);
  },
  { immediate: true } // 初始检查
);
</script>
<style lang="scss" scoped>

.body__box {
  max-width: 1200px;
  margin: 30px auto  0 ;
  display: flex;
  min-height: calc(100vh - 360px);
  justify-content: space-between;

  .router__view {
    width: 980px;
  }
}

.nav__title {
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
  display: block;
  z-index: 1;
  color: #a7a7bb;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: bold;
}

.nav__title:hover {
  color: #93939e;
}

.nav__title--active {
  position: relative;
  display: block;
  z-index: 1;
  color: #000 !important;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: bold;
}

.nav__title--active::after {
  content: "";
  width: 97px;
  height: 12px;
  background: #c0d9ff;
  position: absolute;
  left: 24px;
  z-index: -1;
  top: 15px;
}

.el-button {
  border: 0 !important;
}

.el-dialog {
  border-radius: 5px !important;
}

.el-dialog__title {
  font-size: 20px;
  font-weight: bold;
}

.el-dialog__headerbtn .el-dialog__close {
  font-size: 22px;
  font-weight: bold;
  color: #cfd2dc !important;
}

@font-face {
  font-family: "DINMittbek";
  src: url("https://jscdn.ppkao.com/font/DINMITALT.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// 进入后和离开前保持原位
.fade-right-enter-to,
.fade-right-leave-from {
  opacity: 1;
  transform: none;
}

// 设置进入和离开过程中的动画时长0.3s
.fade-right-enter-active,
.fade-right-leave-active {
  transition: all 0.3s;
}

// 进入前和离开后为透明，并在右侧20px位置
.fade-right-enter-from,
.fade-right-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

</style>
